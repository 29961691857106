import React from 'react';
import { Hero, PolicySection } from './index';

const Privacy: React.FC = () => {
  return (
    <>
      <Hero />
      <PolicySection />
    </>
  );
};

export default Privacy;