import Home from "./home/Home";
import About from "./about/About";
import Contact from "./contact/Contact";
import Investor from "./investors/Investors";
import Partners from "./partners/Partners";
import RoadMap from "./roadmap/RoadMap";
import News from "./news/News";
import FAQ from "./FAQ/FAQ";

import Terms from "./terms/Terms";
import Cookies from "./cookies/Cookies";
import Privacy from "./privacy/Privacy";

export { Home, About, Contact, RoadMap, FAQ, Investor, Partners, News, Terms, Cookies, Privacy };