import React from 'react';
import { Hero } from './index';

const Partners: React.FC = () => {
  return (
    <>
        <Hero />
    </>
  );
};

export default Partners;
